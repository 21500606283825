class Patterns {
    constructor({ svg, symbol }) {
        this.svgElement = svg;
        this.symbolElement = symbol;
        this.symbolLink = `#${symbol.id}`;
    }
    
    static get svgNamespace() {
        return "http://www.w3.org/2000/svg";
    }
    
    static get xlinkNamespace() {
        return "http://www.w3.org/1999/xlink";
    }
    
    /* Return a random integer between min (included) and max (excluded). */
    static getRandomInt(min, max) {
        const minInt = Math.ceil(min),
              maxInt = Math.floor(max);
        return Math.floor(Math.random() * (maxInt - minInt)) + minInt;
    }
    
    /* Return a random number between min (inclusive) and max (exclusive). */
    static getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }
    
    addRandomSymbol(parentNode, fill = "#000000", opacity = 0.5) {
        const useElement = document.createElementNS(Patterns.svgNamespace, "use"),
              width = this.symbolElement.viewBox.baseVal.width,
              height = this.symbolElement.viewBox.baseVal.height,
              x = Patterns.getRandomArbitrary(10, this.svgElement.viewBox.baseVal.width),
              y = Patterns.getRandomArbitrary(10, this.svgElement.viewBox.baseVal.height),
              angle = Patterns.getRandomArbitrary(0, 360),
              scale = Patterns.getRandomArbitrary(1, 4);
        useElement.setAttributeNS(Patterns.xlinkNamespace, "xlink:href", this.symbolLink);
        useElement.setAttribute("width", `${width}`);
        useElement.setAttribute("height", `${height}`);
        useElement.setAttribute("fill", fill);
        useElement.setAttribute("fill-opacity", `${opacity}`);
        useElement.setAttribute("transform", `translate(${x} ${y}) scale(${scale}) rotate(${angle})`);
        parentNode.appendChild(useElement);
    }
    
    drawRandomSymbols(n = 10, fill = "#000000", opacity = 0.5) {
        // Prepare a group element containing all the symbol instances.
        const gElement = document.createElementNS(Patterns.svgNamespace, "g");
        for (let i = 0; i < n; i++) {
            this.addRandomSymbol(gElement, fill, opacity);
        }
        gElement.classList.add("patterns");
        
        // Add the group.
        this.svgElement.appendChild(gElement);
    }
    
    clearSymbols() {
        const gElements = this.svgElement.querySelectorAll("g.patterns");
        for (let i = 0; i < gElements.length; i++) {
            this.svgElement.removeChild(gElements[i]);
        }
    }
    
    get svgString() {
        return this.svgElement.outerHTML;
    }
    
    exportAsSvgFile(clickedLinkElement) {
        const blob = new Blob([this.svgString], {
                  type: "image/svg+xml"
              }),
              url = window.URL.createObjectURL(blob);
        
        clickedLinkElement.target = "_blank";
        clickedLinkElement.download = "Patterns.svg";
        clickedLinkElement.href = url;
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const patterns = new Patterns({
        svg: document.getElementById("illustration"),
        symbol: document.getElementById("triangle")
    });
    
    patterns.drawRandomSymbols(10, "#0090A6", 0.15);
    patterns.drawRandomSymbols(10, "#E8E8E8", 0.15);
    
    /* 
        document.querySelector(".button-redraw").addEventListener("click", () => {
        patterns.clearSymbols();
        patterns.drawRandomSymbols(40, "#000000", 0.15);
        patterns.drawRandomSymbols(30, "#ffffff", 0.4);
    });
    
    document.querySelector(".button-clear").addEventListener("click", () => {
        patterns.clearSymbols();
    });
    
    document.querySelector(".link-download").addEventListener("click", (evt) => {
        patterns.exportAsSvgFile(evt.target);
    });
    */
});

document.addEventListener('DOMContentLoaded', () => {

// Get all "navbar-burger" elements
const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

// Check if there are any navbar burgers
if ($navbarBurgers.length > 0) {

  // Add a click event on each of them
  $navbarBurgers.forEach( el => {
    el.addEventListener('click', () => {

      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');

    });
  });
}

});